<script>
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin.js';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';

  export default {
    mixins: [userFSDocumentMixin],
    name: 'email-preferences',
    data() {
      return {
        shouldReceiveEmails: false,
        isSavingPreferences: false,
      };
    },
    components: {
      AppBar,
    },
    computed: {
      ...mapGetters('AuthModule', ['getEmailPreferences','getCurrentUserFromFirebaseAuth']),
    },
    async created() {
      await this.fetchUser();
      this.shouldReceiveEmails = this.getEmailPreferences;
    },
    methods: {
      ...mapMutations(['setSuccessSnackbarMessage', 'setErrorSnackbarMessage']),
      ...mapActions('AuthModule', ['updateUserData']),

      async savePreferences() {
        this.isSavingPreferences = true;

        try {
          // async action here
          await this.updateUserData({
            uid: this.getCurrentUserFromFirebaseAuth.uid,
            shouldReceiveEmails: this.shouldReceiveEmails,});
          this.setSuccessSnackbarMessage('Your preferences has been set');
          this.shouldReceiveEmails = this.getEmailPreferences;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isSavingPreferences = false;
        }
      },
    },
  };
</script>
